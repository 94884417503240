.modal-container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    min-width: 100%;
    min-height: 100%;
    padding: 20px 30px;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.8);
    animation: fadeUp 0.2s linear 1 forwards;
    z-index: 102;
}