.icon_rank {
  top: -8%;
  left: -9%;
  svg {
    width: 32px;
    height: 45px;
  }
  .bg_ranking {
    svg {
      width: 40px;
      height: 40px;
    }
  }
}
.icon_size_24 {
  svg {
    height: 24px;
    width: 24px;
  }
}
.icon_rank_size27 {
  svg {
    height: 38px;
    width: 27px;
  }
}
.icon_rank_size33 {
  svg {
    height: 33px;
    width: 33px;
  }
  @media screen and (max-width: 500px) {
    svg {
      height: 24px;
      width: 24px;
    }
  }
}
