@media only screen and (max-width: 1250px) {
  .nav_link_home {
    a {
      span {
        font-size: 16px;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 1100px) {
  .nav_link_home {
    a {
      height: 50px;
      padding-left: 15px;
      padding-right: 15px;
      span {
        font-size: 14px;
      }
      svg {
        height: 40px;
        width: 40px;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .icon_viewer_rank {
    svg {
      width: 70px;
    }
  }
}
