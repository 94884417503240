*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

// :root {
//   --primary: #6a59ff;
//   --white: #ffffff;
//   --bg: #f5f5f5;
// }

html {
  // font-size: 62.5%;
  scroll-behavior: smooth;
}

// @media (min-width: 1440px) {
//   html {
//     zoom: 1.5;
//   }
// }

// @media (min-width: 2560px) {
//   html {
//     zoom: 1.7;
//   }
// }

// @media (min-width: 3860px) {
//   html {
//     zoom: 2.5;
//   }
// }

// ::-webkit-scrollbar {
//   width: 1.3rem;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 1rem;
//   background: #797979;
//   transition: all 0.5s ease-in-out;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background: #222224;
// }

// ::-webkit-scrollbar-track {
//   background: #f9f9f9;
// }

// body {
//   // font-size: 1.6rem;
//   background: var(--bg);
// }

// Custom Player
.jw-wrapper {
  background-color: transparent !important;
}

.jwplayer.jw-stretch-uniform .jw-media video {
  object-fit: fill !important;
}
.jwplayer {
  width: 100% !important;
}

.jw-display-container {
  // position: absolute !important;
  z-index: 9999;
}

.video-js .vjs-big-play-button {
  // position: absolute !important;
  z-index: 9999;
}

.video-js.vjs-fluid:not(.vjs-audio-only-mode) {
  height: 100% !important;
}

// New css JWP player
.player-live .jwplayer.jw-flag-aspect-mode {
  height: 100% !important;
}
.player-live > div {
  height: 100%;
}
.jw-controlbar {
  z-index: 999;
}

.jw-autostart-mute {
  z-index: 999;
}

.container {
  // max-width: 124rem;
  // padding: 4rem 1rem;
  max-width: 100% !important;
  margin: 0 auto;
  background-image: url(../../images/Homepage_Background_Banner.png);
  // height: 400px;
  object-fit: cover;
  .slider-controler {
    top: -192px;
  }
  .swiper-wrapper {
    // padding-left: 10%;
    // padding-right: 10%;
    .swiper-slide-active {
      // width: 60% !important;
    }
    .swiper-slide-prev,
    .swiper-slide-next {
      // width: 40% !important;
      filter: blur(4px);
      img {
        filter: brightness(0.4);
        border-radius: 12px;

        // background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    svg {
      height: 40px;
      width: 40px;
    }
    &::after {
      display: none !important;
    }
  }
  .swiper_container {
    // height: 52rem;
    // padding: 2rem 0;
    position: relative;
  }

  .swiper-slide {
    width: 37rem;
    // height: 42rem;
    position: relative;
  }
  .swiper-slide img {
    // width: 37rem;
    height: 378px;
    border-radius: 12px;
    object-fit: scale-down;
  }

  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    display: none;
  }

  .slider-controler {
    position: relative;
    bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slider-controler .swiper-button-next {
    // left: 58% !important;
    // transform: translateX(-58%) !important;
  }
  .slider-controler .slider-arrow {
    background: var(--white);
    // width: 3.5rem;
    // height: 3.5rem;
    border-radius: 50%;
    // left: 42%;
    // transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }

  .slider-controler .slider-arrow ion-icon {
    font-size: 2rem;
    color: #222224;
  }

  .slider-controler .slider-arrow::after {
    content: "";
  }

  .swiper-pagination {
    position: relative;
    width: 15rem !important;
    bottom: 1rem;
  }

  .swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }

  .swiper-pagination .swiper-pagination-bullet-active {
    background: var(--primary);
  }
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

@media (max-width: 500px) {
  // .swiper_container {
  //   height: 47rem;
  // }
  // .swiper-slide {
  //   width: 28rem !important;
  //   height: 36rem !important;
  // }
  // .swiper-slide img {
  //   width: 28rem !important;
  //   height: 36rem !important;
  // }
}

@media (max-width: 990px) {
  .container {
    .slider-controler .swiper-button-next {
      left: 70% !important;
      transform: translateX(-70%) !important;
    }
  }
}

@media (max-width: 450px) {
  .container {
    .slider-controler .swiper-button-next {
      left: 80% !important;
      transform: translateX(-80%) !important;
    }
  }
}

@media (max-width: 990px) {
  .container {
    .slider-controler .swiper-button-prev {
      left: 30% !important;
      transform: translateX(-30%) !important;
    }
  }
}

@media (max-width: 450px) {
  .container {
    .slider-controler .swiper-button-prev {
      left: 20% !important;
      transform: translateX(-20%) !important;
    }
  }
}
