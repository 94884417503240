.name_profile {
  transform: translateY(-111px);
  .img_ava {
    border: 5px solid #fff;
    margin-left: 20px;
    border-radius: 50%;
  }
  .swiper_profile {
    --swiper-navigation-size: 12px;
    --swiper-theme-color: #ffffff;
    .swiper-button-next,
    .swiper-button-prev {
      height: 30px;
      background-color: #10182866;
      width: 30px;
      border-radius: 8px;
      top: 40%;
      box-shadow: 0px 1px 3px 0px #1018281a;
    }
    .swiper-button-next {
      right: 0%;
    }
    .swiper-button-prev {
      left: 0%;
    }
  }
}
.swiper_profile {
  --swiper-navigation-size: 12px;
  --swiper-theme-color: #ffffff;
  .swiper-button-next,
  .swiper-button-prev {
    height: 30px;
    background-color: #10182866;
    width: 30px;
    border-radius: 8px;
    top: 30%;
    box-shadow: 0px 1px 3px 0px #1018281a;
  }
  .swiper-button-next {
    right: 0%;
  }
  .swiper-button-prev {
    left: 0%;
  }
}
.swiper_profile-2 {
  --swiper-navigation-size: 12px;
  --swiper-theme-color: #ffffff;
  .swiper-button-next,
  .swiper-button-prev {
    height: 30px;
    background-color: #10182866;
    width: 30px;
    border-radius: 8px;
    top: 30%;
    box-shadow: 0px 1px 3px 0px #1018281a;
  }
  .swiper-button-next {
    right: 0%;
  }
  .swiper-button-prev {
    left: 0%;
  }
  .swiper-button-prev {
    visibility: hidden;
  }
  .swiper-button-next {
    visibility: hidden;
  }

  @media screen and (max-width: 500px) {
    .swiper-slide {
      width: 75vw !important;
      height: 100% !important;
      img {
        width: 100% !important;
        height: 45vw !important;
        object-fit: cover;
      }
    }
  }
}
.swiper_nomination {
  --swiper-navigation-size: 16px;
  --swiper-theme-color: #667085 !important;
  .swiper-button-next,
  .swiper-button-prev {
    height: 30px;
    background-color: none !important;
    width: 30px;
    scale: 1.75;
    // border-radius: 8px;
    top: 30%;
    // box-shadow: 0px 1px 3px 0px #1018281a;
  }
  .swiper-button-next {
    top: 25% !important;
    right: 0%;
  }
  .swiper-button-prev {
    top: 25% !important;
    left: 0%;
  }
}
.swiper_detail_store {
  --swiper-navigation-size: 12px;
  --swiper-theme-color: #ef6820;
  .swiper-button-next,
  .swiper-button-prev {
    height: 30px;
    background-color: rgba(255, 255, 255, 0.9);
    width: 30px;
    border-radius: 8px;
    top: 30%;
    box-shadow: 0px 1px 3px 0px #1018281a;
  }
  .swiper-button-next {
    right: 20px;
  }
  .swiper-button-prev {
    left: 20px;
  }
}
.text_2_line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.icon_live_profile {
  svg {
    width: 30px;
    height: 15px;
  }
}
.icon_inf_channel {
  svg {
    height: 20px;
    width: 20px;
  }
}
.change_camera {
  svg {
    height: 20px;
    width: 20px;
  }
}
.icon_coinMini {
  svg {
    height: 16px;
    width: 16px;
  }
}
.table_payment {
  .ant-table-container {
    border: 1px solid #f2f4f7;
    border-radius: 8px;
    .ant-table-thead {
      // display: grid;
      // grid-column: 5;
      // width: 100%;
      .ant-table-cell {
        background: #f2f4f7;
        padding: 0px;
        height: 40px;
        text-align: center;
        width: 20%;
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        padding: 0px;
        height: 40px;
        text-align: center;
      }
    }
  }

  .ant-pagination-item {
    min-width: 30px;
    height: 30px;
    margin-inline-end: 0px;

    &.ant-pagination-item-active {
      height: 30px;
      width: 30px;
      background-color: #f2f4f7;
      border-color: white;
      border-radius: 8px;
      a {
        color: #ef6820;
        font-family: "NotoSansBold";
        font-size: 14px;
      }
    }
    a {
      color: #667085;
      font-family: "NotoSansNormal";
      font-size: 14px;
    }
  }
  .ant-pagination-item:hover {
    background-color: #f2f4f7;
    height: 30px;
    width: 30px;
    border-radius: 8px;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    margin-inline-end: 0px;
    svg {
      path {
        fill: #667085;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .ant-pagination {
      justify-content: center;
      align-items: center;
    }
    .ant-table-container {
      border: none;
      border-radius: 0px;
      overflow-x: scroll;
    }
  }
}
.ant-select-item-option-selected {
  background-color: #fef6ee !important;
  color: #101828 !important;
  font-size: 16px !important;
  font-family: "NotoSansNormal";
  font-weight: 400 !important;
}
.ant-input-suffix {
  .ant-input-password-icon {
    svg {
      fill: #ef6820;
    }
  }
}
.modal_forgot_pass {
  width: 600px !important;
  .ant-modal-content {
    padding: 24px 24px;
    .ant-modal-close,
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-body {
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.icon_share {
  svg {
    path {
      fill: #ef6820;
    }
  }
}
