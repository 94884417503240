.wrapper {
  width: 100%;
  height: 100vh;
}

.ant-breadcrumb-link {
  cursor: pointer;
}

.article {
  background: #f0f0f0;
  width: 100%;
  margin: 0;
  min-height: 100vh;
  padding: 65px 15px 20px 20px;
  transition: 0.3s;
  width: calc(100% - 235px);
  margin-left: 235px;

  &.hide {
    width: calc(100% - 90px);
    margin-left: 90px;
  }

  &.article-full {
    width: 100%;
    margin-left: 0;
  }
}

.breadcrumb {
  // margin-top: -20px;
  // margin-bottom: 15px;
}

.overlay-before-render {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000000;
  background: white;
}

.sidebar {
  background: white;
  width: 230px;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  transition: 0.3s;

  &.hide {
    width: 90px;

    .menu {
      .shop-settings-sidebar .open-settings {
        justify-content: center;
        .top-right,
        .ant-btn {
          display: none;
        }

        .top-left .name {
          display: none;
        }
      }
      a {
        align-items: center;
        justify-content: center;
        img {
          margin-right: 0;
          max-width: 30px;
        }

        span {
          width: 0;
          margin-left: 0;
          max-width: 100px;
        }
        .open-settings-sidebar {
          .open-settings {
            justify-content: center;
            align-items: center;
          }
          .divider {
            margin-left: 0px;
          }
          .ant-btn {
            justify-content: center;
            align-items: center;
            margin: 0;
            padding: 0;

            h5 {
              display: none;
            }
          }
        }
      }
    }
  }

  &.hidden {
    transform: translateX(-100%);
  }

  .menu {
    padding-top: 85px;
    padding-bottom: 15px;
    overflow-y: auto;
    height: 100%;

    .shop-settings-sidebar {
      display: flex;
      padding-bottom: 10px;
      color: #555;
      overflow: hidden;
      margin: 0 10px;
      border-radius: 10px;
      font-size: 14px;
      font-weight: 500;
      flex-direction: column;

      .open-settings {
        display: flex;
        flex-direction: row;
        margin: 5px 0;

        .text {
          min-width: 100px;
          padding: 0 5px;
        }

        .name {
          font-size: 16px;
          width: 130px;
          overflow: hidden;
          padding: 5px 0;
          align-items: center;
          margin-left: 10px;
        }
      }

      .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .top-left {
          max-width: 180px;
        }

        .top-left,
        .top-right {
          display: flex;
          flex-direction: row;
          align-items: center;

          .btn-icon {
            margin: 0;
            padding: 0;
          }
        }
      }

      .avatar {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
        margin: 5px 0;
        background-color: rgba($gray, 0.6);
      }

      .shop {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .btn-icon {
        background-color: rgba($gray, 0.6);
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 15px;
          height: 15px;
          margin: 0 2px 2px 0;
        }
      }
    }

    .shop-settings-sidebar .divider {
      height: 1px;
      background-color: rgba($gray, 0.4);
      width: 100%;
    }

    .custom {
      padding: 0 0 10px 0;
    }

    a {
      display: flex;
      padding: 10px 20px;
      align-items: center;
      color: $gray;
      overflow: hidden;
      transition: background 0.3s;
      margin: 0 10px;
      margin-bottom: 5px;
      border-radius: 10px;
      font-size: 14px;
      font-weight: 500;

      .open-settings-sidebar {
        width: 100%;

        .clock {
          width: 20px;
          height: 20px;
          margin-bottom: 3px;
          margin-right: 0;
        }

        .ant-btn {
          width: 100%;
          border-radius: 5px;
        }

        .open-settings {
          display: flex;
          flex-direction: row;
          margin: 5px 0;
        }
        .divider {
          height: 1px;
          background-color: rgba($gray, 0.4);
          width: 100%;
          margin-top: 15px;
        }
      }

      .ant-typography {
        font-weight: 500;
        font-size: 16px;
        color: rgba($gray, 0.9);
      }

      span {
        width: 230px;
        white-space: nowrap;
        overflow: hidden;
        transition: 0.3s;
        font-size: 16px;
        margin-left: 10px;
      }

      img {
        margin-right: 10px;
        width: 30px;
        transition: margin 0.3s;

        &.icon-active {
          display: none;
        }
      }

      &.active {
        background: $primary;
        color: white;

        .ant-typography {
          color: rgba(white, 0.9);
          font-weight: 500;
          font-size: 16px;
        }

        img {
          display: none;

          &.icon-active {
            display: block;
          }
        }
        .open-settings-sidebar .divider {
          width: 0;
          margin: 0;
        }
      }

      &:hover:not(.active) {
        background: $gray3;
        // .open-settings-sidebar .divider {
        //   border-bottom: none;
        //   margin: 0;
        // }
      }
    }
  }
}

.login-page {
  background: white;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  @include flex("", "c");

  &__wrap {
    margin: auto;
    position: relative;
    max-width: 410px;
    width: 100%;
    display: flex;

    .login-wrap {
      z-index: 3;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      padding: 15px;
      max-width: 430px;
      width: 100%;

      p {
        text-align: center;
        margin-bottom: 40px;
        margin-top: 15px;
        color: $gray;
      }

      img {
        height: 100px;
        align-self: center;
      }

      .bottom {
        @include flex("", "s");
      }

      .forgot {
        text-decoration: underline;
        color: $gray;
        margin-top: 50px;
        text-align: center;

        &:hover {
          color: $primary;
        }

        &:before {
          content: "";
          width: 100%;
          max-width: 400px;
          height: 1px;
          background: #e1e1e1;
          position: absolute;
          left: 0;
          bottom: 55px;
        }

        &:after {
          content: "";
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 1px solid #e1e1e1;
          background: white;
          position: absolute;
          bottom: 47px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .submit {
        height: 50px;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 150px;
        width: max-content;
        padding: 0 30px;
        background: $primary;
        color: white;
        font-size: 16px;
        font-weight: 600;
        position: relative;

        &.loading {
          background: rgba($primary, 0.3);
          cursor: default;
        }

        svg {
          margin-right: 10px;
        }

        &:hover:not(.loading) {
          background: rgba($primary, 0.9);
        }
      }
    }
  }

  .term-of-use {
    position: absolute;
    z-index: 3;
    bottom: 20px;
    left: 20px;
    text-decoration: underline;
    font-style: italic;
    color: $gray;

    ul {
      display: flex;

      li:not(:last-child) {
        margin-right: 15px;
        padding-right: 15px;
        border-right: 1px solid #e1e1e1;
      }

      button {
        color: $gray;
      }
    }

    &:hover {
      color: $primary;
    }
  }
}

.header {
  position: fixed;
  height: 45px;
  top: 0;
  z-index: 12;
  left: 0;
  right: 0;
  background: white;
  @include flex("", "s");
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
  padding: 0 15px;
  transition: 0.3s;

  &.hidden {
    transform: translateY(-100%);
  }

  &__left {
    @include flex("");

    h2 {
      font-weight: 500;
      margin-left: 10px;
    }
    .logo {
      width: 240px;
      @include flex("", "s");
      padding-right: 10px;

      img {
        height: 36px;
      }

      svg {
        font-size: 30px;
        margin-top: 3px;
      }
    }
  }

  &__right {
    @include flex("");

    img {
      height: 40px;
      margin-left: 20px;
    }

    h4 {
      height: 40px;
      @include flex("", "c");
      border-radius: 20px;
      padding: 0 20px;
      color: $gray;
    }

    .name {
      background: $primary;
      color: white;
      height: 40px;
      @include flex("", "c");
      border-radius: 20px;
      padding: 0 20px;
      font-size: 16px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        background: rgba($primary, 0.8);
      }
    }

    .date {
      background: $gray3;
      margin-left: 20px;
    }
    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      cursor: pointer;
    }
  }
}

.user-option {
  min-width: 200px;
  padding: 0;

  li {
    padding: 10px 0;

    a {
      width: 100%;
      padding: 10px 15px;
      margin: 0;
      max-height: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    svg {
      margin-right: 10px;
    }
  }

  button {
    @include flex("c");
    padding: 10px 15px;
    width: 100%;
    cursor: pointer;

    &:hover {
      background: $gray3;
    }

    svg {
      margin-right: 10px;
    }
  }

  .user-option_name {
    border-bottom: 1px solid #e1e1e1;
    padding: 10px 15px;
    width: 100%;
    background: #f4f4f4;

    svg {
      margin-right: 10px;
    }
  }
}

.select-shop-option {
  background: white;
  @include shadow;
  width: max-content;
  max-height: calc(90vh - 80px);
  overflow-y: auto;

  button {
    width: 100%;
    display: flex;
    text-align: left;
    padding: 10px 20px;
    min-width: 200px;

    &:hover,
    &.active {
      background: $primary;
      color: white;
    }
  }
}

@include md {
  .article {
    padding: 15px;
    width: calc(100% - 60px);
    margin-left: 60px;
  }

  .overlay {
    width: 100vh;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
  }
}

@media (max-width: 1900px) {
  .menu {
    .sidebar-header {
      display: none;
    }
  }
}

@media (min-width: 1900px) {
  .menu {
    .sidebar-header {
      display: none;
    }
  }
}

@include xs {
  .sidebar {
    right: 0;
    left: initial;
    transform: none;
    transition: 0.3s;

    &.hide {
      width: 300px;
      transform: translateX(100%);

      .user {
        padding: 10px 20px;

        & > div {
          & > div,
          & > img {
            margin-right: 10px;
          }
        }
      }

      .menu {
        a {
          padding: 15px 20px;
          justify-content: flex-start;

          svg {
            margin-right: 10px;
            font-size: 18px;
          }

          span {
            width: auto;
          }
        }
      }

      .logout-btn {
        width: 230px;
        bottom: 20px;
        left: 20px;

        span {
          width: auto;
        }
      }
    }

    .user > button {
      transform: rotate(180deg);
    }
  }

  .article {
    margin: 0;
    width: 100%;
    padding: 10px;
    padding-top: 65px;

    &.hide {
      width: 100%;
      margin: 0;
    }
  }

  .overlay {
    background: rgba(0, 0, 0, 0.5);
  }

  .login-page {
    background: white;

    &__wrap {
      box-shadow: none;
      height: 100vh;
      max-height: initial;

      .right-background {
        display: none;
      }

      .login-wrap {
        box-shadow: none;
        .forgot {
          margin-top: 20px;
        }
      }
    }
    .term-of-use {
      padding-top: 10px;
      bottom: 0;
      ul li:not(:last-child) {
        padding-right: 5px;
        margin-right: 5px;
      }
    }
  }

  .header {
    height: 60px;
    &__left {
      .logo-left {
        display: none;
      }
      .logo {
        width: 50px;
        &.setup {
          display: none;
        }
      }
    }

    &__right {
      .date {
        display: none;
      }
      .header-select-shop {
        display: none;
      }
    }
  }
  .menu {
    .open-settings {
      .text {
        display: none;
      }
    }
    .sidebar-header {
      @include flex("");
      flex-direction: column;
      .name {
        background: $primary;
        color: white;
        height: 40px;
        @include flex("", "c");
        border-radius: 20px;
        padding: 0 20px;
        font-size: 16px;

        cursor: pointer;
        transition: 0.3s;

        &:hover {
          background: rgba($primary, 0.8);
        }
        margin-bottom: 15px;
      }
      .date {
        margin-bottom: 10px;
        background: $gray3;
      }
      h4 {
        height: 40px;
        @include flex("", "c");
        border-radius: 20px;
        padding: 0 20px;
        color: $gray;
      }
    }
  }
}

.input-group {
  .loading-title {
    text-align: center;
  }
  .progress-bar {
    width: 100%;
    height: 15px;
    background-color: rgba(217, 217, 217, 0.7);
    border-radius: 7.5px;
  }
}

.calendar {
  &__header {
    padding: 10px;
    text-align: center;
    font-size: 20px;
  }
  &__content {
    border-left: 1px solid #eee;
    border-top: 1px solid #eee;
    .day-row {
      max-height: calc(100vh / 6);
      display: flex;
    }
    .day {
      position: relative;
      width: calc(100% / 7);
      min-height: calc(100vh / 6 - 35px);
      display: inline-block;
      background-color: white;
      margin: 0;
      padding: 7px 0 0 0;
      box-sizing: border-box;
      z-index: 1;
      text-align: center;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;

      .events-wrapper {
        .events {
          height: 20px;
          margin: 3px;
          border-radius: 5px;
          // padding: 2px 0;
          color: white;
          cursor: pointer;
        }
        .event-1 {
          background-color: $red;
        }
        .event-2 {
          background-color: $blue;
        }
        .event-3 {
          background-color: rgba($gray, 0.3);
        }
      }
    }
    .today {
      background-color: rgba($primary, 0.2);
    }

    .day-of-prev-month,
    .day-of-next-month {
      color: #cfcfcf;
    }
    .week-name {
      position: relative;
      width: calc(100% / 7);
      max-height: calc(100vh / 10 - 40px);
      display: inline-block;
      background-color: $gray3;
      margin: 0;
      box-sizing: border-box;
      z-index: 1;
      text-align: center;
      justify-content: center;
      padding: 3px 0;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
    }
  }
}

// NEW CSS
.login_v2-page {
  // min-height: 100vh;
  padding: 100px 20px;
  padding-top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  .login_v2-content {
    max-width: 630px;
    width: 100%;
  }
}

.login_v2-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 180px;
    height: 80px;
  }
}
.bg-input {
  border-radius: 16px;
  border: 1px solid $gray2;
  padding: 20px;
  background-color: #fafafa;
  width: 100%;

  .input-register {
    font-size: 18px;
    border: 1px solid #999;
    padding: 25px;
    width: 100%;
    border-radius: 99px;
    box-shadow: none;
    height: 50px;
    &::placeholder {
      color: $gray2;
    }
    &:focus {
      outline: $primary solid 1px;
    }
  }
  .bg-checkbox {
    display: flex;
    justify-content: space-between;
    span {
      color: black;
      font-weight: 500;
    }
    h5 {
      color: $primary;
      font-weight: 500;
    }
  }
  button {
    height: 50px;
    width: 100%;
    background-color: $primary;
    color: white;
    border-radius: 99px;
    font-weight: 600;
  }
}

.line-content {
  display: flex;
  align-items: center;
  margin-top: 33px;
  .line-1 {
    height: 1px;
    background: $gray2;
    flex: 1;
  }
  .line-2 {
    flex: 1;
    background: $gray2;
    height: 1px;
  }
  span {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 1px solid $gray2;
  }
}
.bg-button {
  h5 {
    margin-top: 20px;
    text-align: center;
    font-weight: 500;
  }
  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.bg-button_register {
  h5 {
    color: $primary;
    font-weight: 500;
  }
}
.bg-checkbox-register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .bg-step1 {
    display: flex;
    align-items: center;
    width: 80%;
  }
  .circle-step {
    width: 15px;
    height: 15px;
    border-radius: 7.5px;
    background: $gray2;
  }
  .active-circle {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: $primary;
  }
  .circle-step1 {
    width: 15px;
    height: 15px;
    border-radius: 7.5px;
    background: $primary;
  }
  .step-line1 {
    flex: 1;
    height: 2px;
    background: $gray2;
  }
  .bg-step2 {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    span {
      color: $gray2;
    }
  }
  .step-line2 {
    flex: 1;
    height: 1px;
    background: white;
  }
}
.bg-content_register {
  h5 {
    color: black;
    font-weight: 500;
  }
  button {
    background: $primary;
    color: white;
    font-weight: 600;
  }
}
.bg-button_register {
  h5 {
    // margin-top: 20px;
    text-align: center;
    font-weight: 500;
  }
}
.btn-login_register {
  font-weight: 600;
  background-color: white;
  border: 1px solid $primary;
  color: $primary;
  width: 100%;
  height: 50px;
  border-radius: 99px;
}
.bg-form-register {
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    width: 65%;
  }
}
.shop-select {
  &.ant-select {
    .ant-select-selector {
      border: 1px solid $gray2;
      width: 100%;
      height: 50px;
      border-radius: 99px;
    }
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      margin-left: 15px;
      color: black;
    }
  }
}
.btn-shop-upload {
  flex-direction: column;
  display: flex;
  .ant-upload {
    width: 100%;
    .ant-btn {
      display: flex;
      background: white;
      align-items: center;
      color: #666687;
      justify-content: center;
    }
    .anticon-upload {
      font-size: 25px;
    }
  }
  .ant-upload-list-text {
    order: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-upload-list-item-card-actions {
      display: none;
    }
    .ant-upload-text-icon {
      display: none;
    }
    .ant-upload-list-item-name {
      color: black;
    }
  }
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .header__left {
    flex: 0.5;
    display: flex;
    // justify-content: center;
    // align-items: center;
  }
  .header_middle {
    flex: 2;
    width: 100%;
    height: 50px;
    display: flex;
    border-top-left-radius: 99px;
    border-bottom-left-radius: 99px;
    border-top-right-radius: 99px;
    border-bottom-right-radius: 99px;
    .select {
      flex: 1;

      div {
        width: 100%;
      }
      .ant-dropdown-trigger {
        width: 160px;
        .anticon-down {
          margin-left: 35px;
        }
      }
      .ant-select-selector {
        width: 100%;
        height: 100%;
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
        border: 0;
        background: #f5f5f5;
        // padding: 0 20px;
        border: 0.5px solid #f5f5f5;
        border-right: 0px;
        .ant-select-selection-item {
          line-height: 49px;
          margin-left: 25px;
          margin-right: 50px;
        }
      }
      .ant-select-arrow {
        color: #666687;
      }
    }
    .ant-input-affix-wrapper {
      height: 100%;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border: 1px solid #f5f5f5;
      background: #f5f5f5;

      .ant-input {
        background: #f5f5f5;
      }
      .ant-input-suffix {
        margin-right: 15px;
      }
    }
    .ant-input-group-wrapper {
      border-radius: 99px;
      background: #f5f6f8;
      .ant-input-group-addon {
        background: #f5f6f8;
      }
      .ant-input-affix-wrapper {
        height: 50px;
        background: #f5f6f8;
        .ant-input {
          background: #f5f6f8;
        }
      }
    }
  }
  .header__button {
    flex: 0.5;
    display: flex;
    justify-content: center;
    .button_item_header {
      width: 50px;
      height: 50px;
      border-radius: 6px;
      border-color: $primary;
      .btn-item {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 10px;
          color: $primary;
        }
      }
    }
  }

  .header__right {
    flex: 0.5;
    // display: flex;
    justify-content: flex-end;
    margin-right: 36px;
    .ant-btn {
      border: none;
    }
  }
}
.user-option {
  border-radius: 6px;
  min-width: 250px;
  .user-option_namev2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .user-name-img {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 6px;
      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
        margin: 5px 0px;
        background-color: rgba(85, 85, 85, 0.6);
      }
    }
  }
}
.sidebar {
  .menu_v2 {
    padding-top: 85px;
    padding-bottom: 15px;
    overflow-y: auto;
    height: 100%;

    .custom {
      padding: 0 0 10px 0;
    }

    a {
      display: flex;
      padding: 10px 20px;
      align-items: center;
      color: $gray;
      overflow: hidden;
      transition: background 0.3s;
      margin: 0 10px;
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 500;

      .ant-typography {
        font-weight: 500;
        font-size: 16px;
        color: rgba($gray, 0.9);
      }

      span {
        width: 230px;
        white-space: nowrap;
        overflow: hidden;
        transition: 0.3s;
        font-size: 16px;
        margin-left: 10px;
      }

      img {
        margin-right: 10px;
        width: 26px;
        transition: margin 0.3s;

        &.icon-active {
          display: none;
        }
      }

      &.active {
        background: $primary;
        color: white;

        .ant-typography {
          color: rgba(white, 0.9);
          font-weight: 500;
          font-size: 16px;
        }

        img {
          display: none;

          &.icon-active {
            display: block;
          }
        }
      }

      &:hover:not(:first-child) {
        img {
          display: none;

          &.icon-active {
            display: block;
          }
        }

        border: none;
        background: $primary;
        span {
          color: white;
        }
      }
    }
    .shop-settings-sidebar_v2 {
      width: 100%;
      // margin: 0 10px;
      // padding-bottom: 10px;
      .open-settings-time_v2 {
        border-radius: 6px;
        background-color: rgb(50, 116, 246);
      }
      .open-setting-security {
        background: #f5f5f5;
        border-radius: 6px;
        width: 100%;
        height: 130px;
        .settings-total-sales {
          display: flex;

          width: 100%;
          height: 110px;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
        }
        .open-settings {
          display: flex;
        }
        .settings-img-security {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .input-security {
            width: 85%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          img {
            height: 36px;
            width: 80px;
          }
          .input-security {
            Button {
              border: none;
              background: #f5f5f5;
            }
          }
        }
      }
    }
  }
}
@include xl {
  .header {
    .header_middle {
      margin-right: 25px;
      margin-left: -97px;
      display: flex;
      height: 51px;
      .select .ant-select-selector .ant-select-selection-item {
        line-height: 49px;
      }
    }
  }
}
@include md {
  .header {
    .header_middle {
      .select .ant-select-selector .ant-select-selection-item {
        line-height: 49px;
        margin-left: 0;
        margin-right: 0;
      }
    }
    .header__right {
      margin-right: 0;
    }
  }
}
@include sm {
  .header {
    .header__button {
      display: none;
    }
  }
}
@include xs {
  .header {
    .header_middle {
      margin-left: 0;
    }
    .header__right {
      margin-right: 0;
      img {
        margin-right: 0;
      }
      .name_user {
        margin-right: 0;
        span {
          display: none;
        }
      }
    }
  }
}
@media (min-width: 900px) {
  .hide-menu-btn {
    display: none;
  }
}
