.confirm-modal {
    background-color: white;
    border-radius: 8px;
    min-width: 550px;
    min-height: 188px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    .title {
      font-family: "NotoSansJP_700";
      font-size: 16px;
      text-align: center;
      line-height: 24px;
      color: rgba(29, 41, 57, 1);
      margin: 4px 0px 12px 0px;
    }
    .question {
      font-family: "NotoSansJP_400";
      font-size: 16px;
      text-align: center;
      line-height: 20px;
      color: rgba(29, 41, 57, 1);
      margin-bottom: 20px;
    }
    .btns {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 64px;
      gap: 12px;
      padding: 12px 20px;
      border-top: 1px solid rgba(208, 213, 221, 1);
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 228px;
        height: 40px;
        padding: 12px 20px;
        border: 1px solid white;
        border-radius: 8px;
        font-family: "NotoSansJP_500";
      }
      button:first-child {
        color: rgba(71, 84, 103, 1);
        border-color: rgba(208, 213, 221, 1);
      }
      button:last-child {
        color: #f04438;
        border-color: #f04438;
      }
    }
  }