.ant-drawer-content-wrapper {
  width: 256px !important;
  .ant-drawer-header {
    padding: 0px !important;
  }
  .ant-drawer-body {
    padding: 0px !important;
    overflow-x: hidden;
    background-color: #f9fafb;
    overflow-y: hidden;
  }
  .ant-drawer-body:hover {
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: red;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: yellow;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}
