.inp_chat {
  ::placeholder {
    color: #667085;
    font-size: 13px;
  }
}
.box_chat {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    padding-right: 4px;
  }
  &::-webkit-scrollbar-thumb {
    // background-color: red;
    border: 8px solid #d1d1d6;

    &:hover {
      background: #bbb;
    }
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}
.sz15_view {
  svg {
    height: 15px;
  }
}
.icon_live_active {
  svg {
    width: 24px;
    height: 12px;
  }
}
.size_12_icon {
  svg {
    width: 12px;
    height: 12px;
  }
}
.text_3_line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.icon_top1 {
  svg {
    height: 24px;
    width: 24px;
  }
}
.icon_sz_12 {
  svg {
    height: 12px;
    width: 12px;
  }
}
.emoji_picker {
  .epr-category-nav {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
@media (max-width: 800px) {
  .icon_live_color {
    svg {
      height: 24px;
      width: 24px;
      path {
        // fill: white;
      }
    }
  }
  .icon_respon_sub_color {
    svg {
      height: 14px;
      width: 14px;
      path {
        fill: white;
      }
    }
  }
  .icon_respon_happy_color {
    svg {
      height: 20px;
      width: 20px;
      path {
        stroke: white;
      }
    }
  }
  .icon_respon_share_color {
    svg {
      height: 20px;
      width: 20px;
      path {
        fill: white;
      }
    }
  }
  .inp_live_respon_cmt {
    .ant-input {
      background-color: transparent;
      color: white;
    }
    .ant-input::placeholder {
      color: white;
    }
    color: white;
  }
  .box_chat_mobile {
    &::-webkit-scrollbar {
      border-radius: 16px;
      background-color: none;
      width: 1px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      background-color: none;
      border-radius: 16px;
      // border: 4px solid #fff;

      &:hover {
        background: none;
      }
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
}
.modal_change_name {
  .ant-modal-content {
    padding: 0px !important;
    .ant-modal-header {
      height: 48px;
      display: flex;
      align-items: center;
      margin-bottom: 0px;
      border-bottom: 1px solid #d0d5dd;
      padding-left: 16px;
    }
    .ant-modal-body {
      padding: 12px 16px;
    }
    .ant-modal-footer {
      height: 64px;
      margin-top: 0px;
      border-top: 1px solid #d0d5dd;
      padding: 12px 16px;
    }
  }
}
.jw-icon-display {
  display: none !important;
}
.modal_confirm_error {
  .ant-modal-content {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 250px;
  }
  .ant-modal-footer,
  .ant-modal-close {
    display: none;
  }
}
.btn-reaction {
  svg {
    height: 40px;
    width: 40px;
    path {
      fill: #ff7686;
    }
  }
}
@keyframes vapour {
  100% {
    opacity: 0;
    transform: translate(-50%, -500%);
  }
}
@keyframes vapour1 {
  100% {
    opacity: 0;
    transform: translate(-25%, -500%);
  }
}
@keyframes vapour2 {
  100% {
    opacity: 0;
    transform: translate(-20%, -500%);
  }
}
@keyframes vapour3 {
  100% {
    opacity: 0;
    transform: translate(-10%, -500%);
  }
}
.heart {
  color: #ff7686;
  height: 40px;
  width: 40px;
  margin-top: -87px;
  margin-right: 15px;
  position: absolute;
  transform: translate(2%, -25%);
  -webkit-user-select: none;
  animation: vapour 1s ease-in-out;
}
.heart1 {
  transform: translate(-2%, 25%);
  -webkit-user-select: none;
  animation: vapour1 1.5s ease-in-out;
}
.heart2 {
  transform: translate(-2%, 25%);
  -webkit-user-select: none;
  animation: vapour2 2s ease-in-out;
}
.heart3 {
  transform: translate(-2%, 25%);
  -webkit-user-select: none;
  animation: vapour3 2.5s ease-in-out;
}
.heart-box {
  z-index: 100;
  position: relative;
  width: 500px;
  height: 500px;
  margin: auto;
}

// .heart {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyMHB4IiBoZWlnaHQ9IjE4cHgiIHZpZXdCb3g9IjAgMCAyMCAxOCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5oZWFydDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9ImhlYXJ0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNi4wMDAwMDAsIC03LjAwMDAwMCkiIGZpbGw9IiNEMDAyMUIiPiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNS41MjM4MDk1LDI1IEMxNiwyNSAxNiwyNSAxNi40NzYxOTA1LDI1IEwyNC4wOTUyMzgxLDE3IEMyNi41NDk4Njc5LDE0LjUxMzUwNDIgMjYuNTQ5ODY3OSwxMC45MDAxNDM2IDI0LjA5NTIzODEsOSBDMjIuMTUwOTIzNSw2LjQ0MjgzNjc3IDE4LjU4NDg3NSw2LjQ0MjgzNjc3IDE2LjQ3NjE5MDUsOSBDMTYsOSAxNiw5IDE1LjUyMzgwOTUsOSBDMTMuNDE1MTI1LDYuNDQyODM2NzcgOS44NDkwNzY0Niw2LjQ0MjgzNjc3IDcuOTA0NzYxOSw5IEM1LjQ1MDEzMjA3LDEwLjkwMDE0MzYgNS40NTAxMzIwNywxNC41MTM1MDQyIDcuOTA0NzYxOSwxNyBMMTUuNTIzODA5NSwyNSBaIj48L3BhdGg+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=");
//   background-repeat: no-repeat;
//   height: 22px;
//   width: 22px;
// }

@mixin animation($name, $duration, $delay, $count) {
  -webkit-animation: $name $duration $delay $count;
  -moz-animation: $name $duration $delay $count;
  -o-animation: $name $duration $delay $count;
  -ms-animation: $name $duration $delay $count;
  animation: $name $duration $delay $count;
}

.animate-topright {
  position: absolute;
  @include animation(movetopRight, 2s, 0.5s, infinite);
}

// .animate-bottomright {
//   position: absolute;
//   @include animation(movebottomRight, 4.5s, 1.7s, infinite);
// }

.animate-topleft {
  position: absolute;
  @include animation(movetopLeft, 2s, 0.4s, infinite);
}

// .animate-bottomleft {
//   position: absolute;
//   @include animation(movebottomLeft, 7.5s, 4.8s, infinite);
// }

.animate-up {
  position: absolute;
  @include animation(moveup, 2s, 0.3s, infinite);
}

// .animate-down {
//   position: absolute;
//   @include animation(movedown, 5.5s, 5.5s, infinite);
// }

// .animate-left {
//   position: absolute;
//   @include animation(moveleft, 5.5s, 2.9s, infinite);
// }

// .animate-right {
//   position: absolute;
//   @include animation(moveright, 5.5s, 3.4s, infinite);
// }

@keyframes movetopRight {
  0% {
    top: 50%;
    left: 50%;
    opacity: 0.9;
  }
  5% {
    top: 48%;
    left: 48%;
    opacity: 0.9;
  }
  100% {
    top: 10%;
    left: 50%;
    opacity: 0;
  }
}

// @keyframes movebottomRight {
//   0% {
//     top: 50%;
//     left: 50%;
//     opacity: 0.9;
//   }
//   100% {
//     top: 90%;
//     left: 90%;
//     opacity: 0;
//   }
// }

@keyframes movetopLeft {
  0% {
    top: 50%;
    left: 50%;
    opacity: 0.9;
  }
  5% {
    top: 48%;
    left: 48%;
    opacity: 0.9;
  }
  10% {
    top: 47%;
    left: 47%;
    opacity: 0.9;
  }
  15% {
    top: 46%;
    left: 46%;
    opacity: 0.9;
  }
  20% {
    top: 45%;
    left: 45%;
    opacity: 0.9;
  }
  100% {
    top: 10%;
    left: 45%;
    opacity: 0;
  }
}

// @keyframes movebottomLeft {
//   0% {
//     top: 50%;
//     left: 50%;
//     opacity: 0.9;
//   }
//   100% {
//     top: 90%;
//     left: 5%;
//     opacity: 0;
//   }
// }

@keyframes moveup {
  0% {
    top: 50%;
    left: 50%;
    opacity: 0.9;
  }
  // 40% {
  //   top: 40%;
  //   left: 50%;
  //   right: 40%;
  //   opacity: 0.9;
  // }
  100% {
    top: 0%;
    left: 45%;
    opacity: 0;
  }
}

// @keyframes movedown {
//   0% {
//     top: 50%;
//     left: 50%;
//     opacity: 0.9;
//   }
//   100% {
//     top: 100%;
//     left: 45%;
//     opacity: 0;
//   }
// }

// @keyframes moveleft {
//   0% {
//     top: 50%;
//     left: 50%;
//     opacity: 0.9;
//   }
//   100% {
//     top: 45%;
//     left: 10%;
//     opacity: 0;
//   }
// }

// @keyframes moveright {
//   0% {
//     top: 50%;
//     left: 50%;
//     opacity: 0.9;
//   }
//   100% {
//     top: 45%;
//     left: 97%;
//     opacity: 0;
//   }
// }
