#rswps-form {
  width: 40% !important;
  .sq-card-iframe-container {
    border-color: #ef6820 !important;
  }
  #rswp-card-button {
    background-color: #ef6820 !important;
  }
}
.form-square {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  #rswps-form {
    width: 40% !important;
  }
  @media screen and (max-width: 500px) {
    #rswps-form {
      width: 100% !important;
    }
  }
}
.text_input_coin {
  textarea {
    cursor: pointer;
    border-radius: 10px;
    border: none;
    height: 94px !important;
    max-height: 94px !important;
    min-height: 94px !important;
    font-weight: 700;
    font-size: 18px;
    font-family: "NotoSansBold";
    padding-bottom: 12px !important;
    padding-top: 12px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    align-items: center !important;
    display: flex !important;
    width: 100% !important;
  }
}
.bg_choose_input {
  .text_input_coin {
    textarea {
      background: #fefaf5;
    }
  }
}
.bg_choose_input_hover:hover {
  .text_input_coin {
    textarea {
      background: #fefaf5;
    }
  }
}
.modal_event_live {
  width: fit-content !important;
  .ant-modal-content {
    padding: 0px;
    .ant-modal-body {
      border-radius: 8px;
    }
    .ant-modal-footer {
      display: none;
    }
  }
}
