.support-contact-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 900px) {
    padding: 0 20px;
  }
  .input-row {
    .label {
      font-weight: 500;
      color: #1d2939;
      font-family: "NotoSansMedium";
    }
  }
  .form-body {
    width: 800px;
    @media (max-width: 900px) {
      width: 100%;
    }
  }
  .privacy-info {
    text-align: center;
  }
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
    accent-color: #ef6820;
    border: 1px solid #d0d5dd !important;
    border-radius: 8px;
  }
  textarea {
    resize: none;
  }
}
