.pop_acc {
}
.ant-popover-content {
  // width: 250px;
  .ant-popconfirm-buttons,
  .ant-popconfirm-message-icon {
    display: none;
  }
  .ant-popover-inner {
    padding: 0px !important;
  }
  .ant-popconfirm-message-text {
    width: 100% !important;
  }
}
@media (max-width: 500px) {
  .ant-dropdown-menu {
    width: 250px;
  }
  .modal_sign_up {
    .ant-modal-content {
      padding: 0px 20px !important;
      .ant-modal-close {
        display: block !important;
      }
      .ant-modal-body {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
      }
    }
  }
}
@media (max-width: 675px) {
  .card_btn {
    justify-content: flex-end;
    .inp_search,
    .btn_search_head {
      display: none;
    }

    .btn_active {
      display: block;
      display: flex;
    }
  }
}
.modal_payment {
  width: 800px !important;
  .ant-modal-content {
    height: 100%;
    padding: 0px;
    .ant-modal-close,
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-body {
      // height: 100%;
      // width: 100%;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // padding-top: 40px;
      // padding-bottom: 40px;
    }
  }
}
.modal_sign_up {
  width: 600px !important;
  .ant-modal-content {
    height: 100%;
    padding: 0px 60px;
    border-radius: 20px !important;
    // .ant-modal-close,
    .ant-modal-footer {
      display: none;
    }
    .ant-modal-body {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  .hide_pass_sign {
    .ant-input-password {
      .ant-input-suffix {
        span {
          svg {
            fill: #ef6820;
          }
        }
      }
    }
  }
}
.btn_icon {
  svg {
    height: 24px;
    width: 24px;
  }
}
.card_search_home {
  &::-webkit-scrollbar {
    background-color: white;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
    border: 10px solid #bbb;

    &:hover {
      background: #bbb;
    }
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.digitGroup {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.digitGroup input {
  // outline: 0 !important;
  user-select: none !important;
  width: 25%;
  height: 70px;
  // background-color: #c5dbf5;
  font-weight: bold !important;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  // line-height: 50px;
  text-align: center;
  font-size: 32px;
  color: #1d2939;
  margin: 0;
}

.digitGroup input:focus {
  border: 2px solid #ef6820 !important;
}

.digitGroup input:active {
  border: 2px solid #ef6820 !important;
}

.digitGroup .splitter {
  padding: 5px 0;
  color: rgb(0, 0, 0);
  font-size: 25px;
  margin: 0;
}

.prompt {
  margin-bottom: 20px;
  font-size: 20px;
  color: white;
}

.formSection {
  max-width: 500px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0px 0px 8px #ddd;
  padding: 20px;
}

.w-100 {
  width: 100%;
}

/* Media query for mobile devices */
@media (max-width: 480px) {
  .digitGroup {
    gap: 5px !important;
  }
  .digitGroup input {
    width: 40px !important;
  }
  .digitGroup .splitter {
    font-size: 25px !important;
  }
}
