.text_detail {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.text_1_line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.recomment_bg {
  background-image: url(../../images/Homepage_Background_Schedule.png);
  // height: 418px;
  width: 100%;
  .swiper-button-next,
  .swiper-button-prev {
    // position: unset;
    top: 43%;
  }
  .swiper-button-prev {
    // left: 95%;
  }
  .swiper-button-next {
    right: 20px;
  }
  .swiper-wrapper {
    position: unset;
  }
  --swiper-navigation-size: 40px;
  --swiper-theme-color: #0c111d;
}
.detail_vid:hover {
  .text_vid {
    z-index: 50;
  }
}
.rank_scroll {
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    padding-right: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: red;
    border-radius: 99px;
    border: 8px solid #eaecf0;

    &:hover {
      background: #bbb;
    }
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}
.sz12_view {
  svg {
    width: 12px;
  }
}
.bg_linear {
  background: linear-gradient(180deg, rgba(16, 24, 40, 0) 0%, rgba(16, 24, 40, 0.8) 100%);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.swiper1 {
  .swiper-button-next,
  .swiper-button-prev {
    top: 46% !important;
  }
}
.play_icon_hover:hover {
  backdrop-filter: brightness(0.5);
  border-radius: 8px;
  .icon_play {
    display: block;
  }
}
.ant-breadcrumb-link {
  font-family: "NotoSansNormal";
  color: "#98A2B3" !important;
  :last-child {
    font-family: "NotoSansMedium";
    color: "#475467" !important;
  }
}
.size_content_hd {
  max-width: 1200px;
}
@media (max-width: 500px) {
  .size_content_hd {
    max-width: 500px !important;
  }
  .size_icon_28 {
    svg {
      height: 28px !important;
      width: 28px !important;
    }
  }
  .icon_size_52 {
    svg {
      width: 140px;
      height: 52px;
    }
  }
  .icon_respon_detail {
    svg {
      path {
        stroke: #667085;
      }
    }
  }
  .icon_size_20 {
    svg {
      height: 20px;
      width: 20px;
    }
  }
}
@media (max-width: 850px) {
  .size_content_hd {
    max-width: 100% !important;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 1160px) {
  .size_icon_28 {
    svg {
      height: 35px !important;
      width: 35px !important;
    }
  }
}
@media (max-width: 1080px) {
  .size_content_hd {
    width: 800px !important;
  }
}

@media (max-width: 1400px) {
  .size_content_hd {
    max-width: 1060px;
  }
}
